<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="productImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="productImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
  </div>
</template>

<script>
import productImg2 from "@/page/truongtaionline/components/img/4_2.png";
import productImg3 from "@/page/truongtaionline/components/img/footer.png";
export default {
  data() {
    return {
      productImg2: productImg2,
       productImg3: productImg3,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}
.imgBox1 {
  width: 100%;
  height: 250px;
  background-color: #f3f3f3;
}
.imgBox2{
  margin-top: 80px;
}
.imgBox2,.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}



img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}


.img2,.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem2,.imgItem3 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
margin-top: -40px;  
}

</style>

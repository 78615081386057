<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400">
        <img :src="importedImg2" :alt="altText" class="imgItem2" />
      </div>
    </div>

    <div class="imgBox3 min-w-1400">
      <div class="img3 max-w-1400">
        <img :src="importedImg3" :alt="altText" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4 max-w-1400">
        <img :src="importedImg4" :alt="altText" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5 max-w-1400">
        <img :src="importedImg5" :alt="altText" class="imgItem5" />
      </div>
    </div>
    <div class="imgBox6 min-w-1400">
      <div class="img6 max-w-1400">
        <div class="box1">
          <h2>TRUONG TAI PHAT VIET NAM COMPANY LIMITED</h2>
          <div class="box2">
            <span
              >Số 01, phố Hai Bà Trưng, Phường Lê Hồng <br />Phong, Thành phố
              Thái Bình, Thái Bình</span
            >
          </div>
        </div>
        <!-- 使用 Flex 布局，水平排列三个大标题 -->
        <div class="titlesContainer">
          <!-- 第一个大标题 -->
          <div class="titleContainer1">
            <h2>Liên hệ chúng tôi</h2>
            <!-- 第一个大标题下的小标题 -->
            <div class="subtitles">
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/phone.png"
                  alt=""
                />
                <span>+84 57823408</span>
              </div>
              <div class="iconTextWrapper">
                <img
                  src="@/page/truongtaionline/components/img/email.png"
                  alt=""
                />
                <span>service@truongtaionline.com</span>
              </div>
            </div>
          </div>

          <!-- 第二个大标题 -->
          <div class="titleContainer2">
            <h2>Về chúng tôi</h2>
            <!-- 第二个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul2">
                <li>
                  <a @click="jumpToTarget('1')">Câu chuyện của chúng ta</a>
                </li>
                <li>
                  <a @click="jumpToTarget('2')">Sứ mệnh</a>
                </li>
                <li>
                  <a @click="jumpToTarget('3')">Nhiệm vụ</a>
                </li>
              </ul>
            </div>
          </div>

          <!-- 第三个大标题 -->
          <div class="titleContainer3">
            <h2>
              <router-link to="/about" tag="li" class="triangle">
                <a>Về sản phẩm </a>
              </router-link>
            </h2>
            <!-- 第三个大标题下的小标题 -->
            <div class="subtitles">
              <ul class="ul3">
                <router-link to="/user" tag="li" class="triangle">
                  <a>Phát triển nhân viên</a>
                </router-link>
                <router-link to="/questions" tag="li" class="triangle">
                  <a>Trung tâm trợ giúp</a>
                </router-link>
                <li class="triangle">
                  <a
                    href="https://vgtiw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(AppStore)
                  </a>
                </li>
                <li class="triangle">
                  <a
                    href="https://vgtaw.truongtaionline.com/help/privacy_policy"
                  >
                    Chính sách bảo mật(GooglePlay)
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/truongtaionline/components/img/2_1.png";
import importedImg2 from "@/page/truongtaionline/components/img/2_2.png";
import importedImg3 from "@/page/truongtaionline/components/img/2_3.png";
import importedImg4 from "@/page/truongtaionline/components/img/2_4.png";
import importedImg5 from "@/page/truongtaionline/components/img/2_5.png";
import importedImg6 from "@/page/truongtaionline/components/img/footer.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      importedImg6: importedImg6,

      altText: "图片",
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    jumpToTarget(image) {
      this.$router.push({ name: "/", params: { image: image } });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.imgBox1 {
  margin-top: 80px;
}
.imgBox1,
.imgBox2,
.imgBox4,
.imgBox6 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox3,
.imgBox5 {
  display: flex;
  justify-content: center;
  width: 100%;
}
img {
  display: block;
}
.img1,
.img2,
.img4,
.img6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.img3,
.img5 {
  margin-left: 45px;
  margin-right: 45px;
}
.imgItem1,
.imgItem2,
.imgItem4,
.imgItem6 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}

.imgItem3 {
  max-width: 100%;
  margin-bottom: 100px;
}
.imgItem5 {
  max-width: 100%;
  margin-top: 100px;
  margin-bottom: 100px;
}
.imgBox6 {
  display: flex;
  justify-content: center;
  background-color: #242dda;
  height: 500px; /* 固定高度 */
  min-width: 1400px;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
}

.titlesContainer {
  display: flex;
  width: 100%;
  margin-top: 30px;
}

.titleContainer1 {
  width: 400px;
  position: absolute;
  margin-top: 30px;
  margin-left: 200px;
}
.titleContainer2 {
  margin-top: 30px;
  width: 400px;
  margin-left: 600px;
}
.titleContainer3 {
  margin-top: 20px;
}
.subtitles {
  display: flex;
  flex-direction: column;
}

li {
  color: #ffffff;
  margin-top: 10px;
}
h2 {
  color: #ffffff;
}
.Li2 {
  width: 1000px;
}
.ul2 li {
  width: 220px;
}

li a {
  color: #ffffff;
  text-decoration: none; /* 添加这一行，去掉下划线 */
  cursor: pointer; /* 添加这一行，显示手型光标 */
}
.a2 {
  width: 400px;
}
li {
  font-size: 18px;
}
.Li3 {
  color: #c1c0c0;
  font-size: 14px;
}
.box1 {
  margin-left: 200px;
  margin-top: 50px;
}
.box2 {
  margin-top: 20px;
}
span {
  font-size: 20px;
  color: #ffffff;
}
.iconTextWrapper {
  display: flex; /* Use flexbox */
  align-items: center; /* Align items vertically centered */
  margin-top: 10px; /* Adjust margin as needed */
}

.iconTextWrapper img {
  margin-right: 10px; /* Adjust spacing between icon and text */
}
h2 a{
  font-size: 24px;
}
</style>
